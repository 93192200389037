<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div
          v-if="buttonItems"
          class="ml-1 mr-1 mb-3 row justify-content-between"
        >
          <div class="mb-2" v-for="(group, index) in buttonItems" :key="index">
            <BaseButton
              v-for="(item, index) in group"
              :key="index"
              class="mr-2"
              :variant="item.variant"
              :icon="item.icon"
              :columnInfo="item.columnInfo"
              :disabled="item.disabled"
              @click="item.onClickCallback"
            />
          </div>
        </div>
        <section data-class="work-list-table">
          <!-- テーブル -->
          <BaseTable
            selectMode="range"
            :fields="fields"
            :items="tableItems"
            @row-selected="onRowSelected"
          />
        </section>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- クリアボタン -->
        <BaseButton
          variant="primary"
          :columnInfo="resultControlInfo.clear"
          :disabled="isDisabled"
          @click="clear(obj)"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="range"
      :useUnset="false"
      @after-close-set="addPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- 解除モーダル -->
    <ConfirmModal
      id="cancel-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="remove(obj)"
    />
  </div>
</template>

<script>
import { openSubModal } from '@/common/Common.js'
import {
  init,
  addPlace,
  deletePlace,
  clear,
  goRegister,
  goRemove,
  register,
  remove,
} from './PlaceSameSettingModal.js'
export default {
  components: {
    PlaceModal: () => import('./PlaceModal.vue'),
  },

  data() {
    return {
      tableItems: [], //CurrentList
      selectedItem: [],
      searchList: [], //SearchList
      obj: this,
      registerMessage: null,
      isDisabled: true,
    }
  },

  props: {
    representativePlaceIds: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    representativePlaceIds() {
      init(this)
    },
  },

  computed: {
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLACE_SAME_SETTING
      )('result_ctrl_info')
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLACE_SAME_SETTING
      )(this.DOMAIN_NAME.FIELDS)
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 場所追加ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.add_place,
            disabled: !this.selectedItem,
            onClickCallback: () => {
              openSubModal(this, 'place-modal')
            },
          },
          // 場所削除ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.delete_place,
            onClickCallback: () => {
              deletePlace(this)
            },
          },
          // 登録ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // 解除ボタン
          {
            variant: 'primary',
            columnInfo: this.resultControlInfo.cancel,
            onClickCallback: () => {
              goRemove(this)
            },
          },
        ],
      ]
    },
  },

  methods: {
    clear,
    register,
    remove,

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    addPlace(selectedPlaces) {
      addPlace(this, selectedPlaces)
    },
  },
}
</script>
