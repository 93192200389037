import { openMsgModal, openSubModal } from '@/common/Common.js'
import { MSG_TYPE, PROCESS_KIND } from '@/common/const.js'
import PlaceService from './PlaceService'
import _ from 'lodash'

export function init(obj) {
  if (obj.representativePlaceIds.length > 0) {
    let searchCondition = {
      screenId: obj.SCREEN_ID.PLACE_SAME_SETTING,
      domainName: 'result_fields',
      representativePlaceIds: obj.representativePlaceIds,
    }

    obj.$store.dispatch('init/setServiceLoading')
    PlaceService.search(searchCondition)
      .then(
        (response) => {
          obj.searchList = _.cloneDeep(response.data.resultList)
          obj.tableItems = _.cloneDeep(response.data.resultList)

          obj.tableItems.forEach((element) => {
            if (element.place_type == '20') {
              element.chkRepresentative = true
            }
          })
        },
        (e) => {
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message
          }
          // エラーモーダル表示
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  } else {
    obj.tableItems = []
    obj.selectedItem = []
    obj.searchList = []
  }
}

export function clear(obj) {
  obj.tableItems = _.cloneDeep(obj.searchList)
  obj.isDisabled = true
}

export function addPlace(obj, selectedPlaces) {
  if (selectedPlaces != undefined && selectedPlaces.length > 0) {
    selectedPlaces.forEach((selectedPlace) => {
      let exist = false
      obj.tableItems.some((element) => {
        if (element.place_id == selectedPlace.place_id) {
          exist = true
          return true
        }
      })

      if (exist == false) {
        // 場所纏めデータの場合
        if (selectedPlace.place_type !== '10') {
          openMsgModal(
            obj,
            MSG_TYPE.E,
            obj.$store.getters['init/getMessage']('MB01S004_MG008E')
          )
        } else {
          // 場所一覧の最下部に追加する
          obj.tableItems.push(selectedPlace)
          obj.isDisabled = false
        }
      }
    })
  }
}

export function deletePlace(obj) {
  if (obj.selectedItem.length == 0) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG969E')
    )
    return
  }
  obj.selectedItem.forEach((element) => {
    const index = obj.tableItems.indexOf(element)
    obj.tableItems.splice(index, 1)
  })
  obj.isDisabled = false
}

export function goRegister(obj) {
  // 行が２行以上存在するかどうかチェックする。エラー時はメッセージを出力する。
  if (obj.tableItems.length < 2) {
    // ２件以上の場所を指定してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('MB01S004_MG009E')
    )
    return
  }

  let checkedCount = 0
  obj.tableItems.forEach((element) => {
    if (element.chkRepresentative == true) {
      checkedCount++
    }
  })

  if (checkedCount != 1) {
    // 代表チェックボックスを一つだけチェックしてください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('MB01S004_MG006E')
    )
    return
  }

  // {0}処理を行います。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.register.label
  )

  openSubModal(obj, 'register-modal')
}

export function goRemove(obj) {
  // 一覧にデータが存在しません。
  if (obj.tableItems.length == 0) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('MB01S004_MG005E')
    )
    return
  }

  // {0}処理を行います。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.cancel.label
  )

  openSubModal(obj, 'cancel-modal')
}

export function register(obj) {
  obj.$bvModal.hide('register-modal')

  let registerList = getRegisterList(obj)
  obj.$store.dispatch('init/setServiceLoading')
  PlaceService.registerSameList(registerList)
    .then(
      () => {
        openMsgModal(
          obj,
          MSG_TYPE.I,
          obj.$store.getters['init/getMessage'](
            'YZ00MG995I',
            this.resultControlInfo.register.label
          )
        )
        obj.$bvModal.hide('same-setting-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function remove(obj) {
  obj.$bvModal.hide('cancel-modal')

  let removeList = getRemoveList(obj)
  obj.$store.dispatch('init/setServiceLoading')
  PlaceService.removeSameList(removeList)
    .then(
      () => {
        openMsgModal(
          obj,
          MSG_TYPE.I,
          obj.$store.getters['init/getMessage'](
            'YZ00MG995I',
            this.resultControlInfo.cancel.label
          )
        )
        obj.$bvModal.hide('same-setting-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function getRegisterList(obj) {
  let editedList = []
  let representativePlaceId = null

  let id = 0
  obj.tableItems.forEach((element) => {
    if (element.chkRepresentative == true) {
      representativePlaceId = element.place_id
    }
    let isAdded = true
    if (element.place_id != null) {
      id = element.place_id
      obj.searchList.some((before) => {
        if (before.place_id == id) {
          isAdded = false
          editedList.push(
            getSamePlaceElement(
              element,
              representativePlaceId,
              PROCESS_KIND.UPDATE
            )
          )
          return true
        }
      })
    }
    if (isAdded) {
      editedList.push(
        getSamePlaceElement(element, representativePlaceId, PROCESS_KIND.INSERT)
      )
    }
  })

  obj.searchList.forEach((element) => {
    let isDeleted = true
    if (element.place_id != null) {
      id = element.place_id
      editedList.some((edited) => {
        if (edited.placeId == id) {
          isDeleted = false
          return true
        }
      })
    }
    if (isDeleted) {
      editedList.push(
        getSamePlaceElement(element, representativePlaceId, PROCESS_KIND.DELETE)
      )
    }
  })

  return editedList
}

function getRemoveList(obj) {
  let removeList = []

  // 場所一覧に表示されている全ての行
  obj.tableItems.forEach((element) => {
    removeList.push(getSamePlaceElement(element, 0, PROCESS_KIND.UPDATE))
  })

  obj.searchList.forEach((element) => {
    let isDeleted = true
    let id = element.place_id
    removeList.some((removeElement) => {
      if (removeElement.placeId === id) {
        isDeleted = false
        return true
      }
    })

    if (isDeleted) {
      removeList.push(getSamePlaceElement(element, 0, PROCESS_KIND.DELETE))
    }
  })

  return removeList
}

function getSamePlaceElement(element, representativePlaceId, processKind) {
  let isRepresentativePlace = false
  if (representativePlaceId === element.place_id) {
    isRepresentativePlace = true
  }

  return {
    placeId: element.place_id,
    placeType: element.place_type,
    representativePlaceId: element.representative_place_id,
    isRepresentativePlace: isRepresentativePlace,
    processKind: processKind,
  }
}
